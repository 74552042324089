import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
// import company from './modules/company'
import tagPersisted from '@/store/persisted/tags'

// 持久化
import UserPersisted from './persisted/userState'
// import CompanyPersisted from './persisted/companyState'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    settings,
    user,
    tagsView,
    permission,
  },
  getters,
  plugins: [UserPersisted]
})

export default store
