/**
 * company Store 持久化
 */
import createPersistedState from 'vuex-persistedstate'

const tagPersisted = createPersistedState({
  key: 'Tags',
  storage: window.localStorage,
  paths: [] //'tagsView.visitedViews', 'tagsView.cachedViews',
})

export default tagPersisted
