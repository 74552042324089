const state = {
  token: '',
  domainCode: "",
  email: "",
  id: '',
  name: "",
  orgs: '',
  phone: '',
  roles: '',
  sex: '',
  menuList: '',
  menuListAll: [],
  isEditPass: false, //是否需要修改密码
  isResetPass: false,//是否需要重置密码
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER(state, user = {}) {
    state.sex = user.sex
    state.token = user.token || ''
    state.id = user.id || ''
    state.name = user.name || ''
    state.domainCode = user.domainCode || ''
    state.email = user.email || ''
    state.orgs = user.orgs || ''
    state.phone = user.phone || ''
    state.roles = user.roles || ''
  },
  SET_MENU(state,menu = []){
    state.menuList = menu;
  },
  SET_MENU_All(state,menu = []){
    state.menuListAll = menu;
  },
  SET_IS_EDIT_PASS(state,view){
    state.isEditPass = view;
  },
  SET_IS_RESET_PASS(state,view){
    state.isResetPass = view;
  },
}

const actions = {
  set_token({commit}, data) {
    commit('SET_TOKEN', data)
  },
  set_user({commit}, data) {
    commit('SET_USER', data);
  },
  set_is_reset_password({commit}, data) {
    commit('SET_IS_RESET_PASS', data)
  }

}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
