let Routes = [
  {
    path: '/workspace',
    component: () => import('@/layout/index'),
    children: [
      //企业中心
      {
        path: '',
        component: () => import('@/layout/layout-workspace'),
        redirect: 'enterprise-info',
        children: [
          {
            path: 'enterprise-info',
            name: 'enterprise-info',
            component: () => import('@/views/enterprise/list'),
            meta: {
              title: '出账公司',
              icon: 'Enterprises',
              type: true,
            },
          },
          {
            path: 'enterprise-info-edit',
            name: 'enterprise-info-edit',
            hidden: true,
            component: () => import('@/views/enterprise/information'),
            meta: {
              title: '出账公司新增/编辑',
              icon: 'Enterprises',
              type: true,
              parent: '/workspace/enterprise-info'
            },
          }
        ],
      },
      //客商 //用户
      {
        path: '',
        component: () => import('@/layout/layout-workspace'),
        redirect: 'customerMerchantsInfo-user',
        children: [
          {
            path: 'customerMerchantsInfo-user',
            name: 'customerMerchantsInfo-user',
            component: () => import('@/views/enterprise/customer-management/customerMerchantsInfo'),
            meta: {
              title: '客户管理',
              icon: 'user',
              roles: ['HR', 'HR_ELE', 'ADMIN'],
              type: true,
            }
          },
          {
            path: 'customerMerchantsInfoEdit-userInfo/:number',
            name: 'customerMerchantsInfoEdit-userInfo',
            component: () => import('@/views/enterprise/customer-management/customerMerchantsInfoEdit'),
            meta: {
              title: '客户管理编辑',
              icon: '',
              parent: '/workspace/customerMerchantsInfo-user'
            },
            hidden: true
          },
          {
            path: 'customerMerchantsInfoEdit-userInfo',
            name: 'customerMerchantsInfoEdit-userInfo2',
            component: () => import('@/views/enterprise/customer-management/customerMerchantsInfoEdit'),
            meta: {
              title: '客户管理编辑',
              icon: '',
              parent: '/workspace/customerMerchantsInfo-user'
            },
            hidden: true
          }
        ]
      },
      //客商管理 供应商
      {
        path: '',
        component: () => import('@/layout/layout-workspace'),
        redirect: 'customerMerchantsInfo-business',
        children: [
          {
            path: 'customerMerchantsInfo-business',
            name: 'customerMerchantsInfo-business',
            component: () => import('@/views/enterprise/customer-management/customerMerchantsInfo'),
            meta: {
              title: '供应商管理',
              icon: 'customer',
              roles: ['HR', 'HR_ELE', 'ADMIN'],
              type: true,
            }
          },
          {
            path: 'customerMerchantsInfoEdit-businessInfo/:number',
            name: 'customerMerchantsInfoEdit-businessInfo',
            component: () => import('@/views/enterprise/customer-management/customerMerchantsInfoEdit'),
            meta: {
              title: '供应商管理编辑',
              icon: '',
              parent: '/workspace/customerMerchantsInfo-business'
            },
            hidden: true
          },
          {
            path: 'customerMerchantsInfoEdit-businessInfo',
            name: 'customerMerchantsInfoEdit-businessInfo2',
            component: () => import('@/views/enterprise/customer-management/customerMerchantsInfoEdit'),
            meta: {
              title: '供应商管理编辑',
              icon: '',
              parent: '/workspace/customerMerchantsInfo-business'
            },
            hidden: true
          }
        ]
      },
      //组织架构
      {
        path: '',
        redirect: 'departmentIndex',
        component: () => import('@/layout/layout-workspace'),
        children: [
          {
            path: '',
            redirect: 'index',
            hidden: true
          },
          {
            path: 'department',
            name: 'departmentIndex',
            component: () => import('@/views/enterprise/department/department'),
            meta: {
              title: '组织架构',
              icon: 'department',
            }
          }
        ],
      },
      //人员管理
      {
        path: '',
        component: () => import('@/layout/layout-workspace'),
        redirect: 'userIndex',
        children: [
          {
            path: '',
            redirect: 'index',
            hidden: true
          },
          {
            path: 'user',
            name: 'userIndex',
            component: () => import('@/views/user-management'),
            meta: {
              title: '人员管理',
              icon: 'user-group',
            }
          }
        ]
      },
      //车牌档案
      {
        path: '',
        component: () => import('@/layout/layout-workspace'),
        redirect: 'license-plate-file-index',
        children: [
          {
            path: 'license-plate-file',
            name: 'license-plate-file-index',
            component: ()=> import('@/views/license'),
            meta: {
              title: '车牌档案',
              icon: 'license',
            }
          }
        ]
      },
      //资产分类
      {
        path: '',
        component: () => import('@/layout/layout-workspace'),
        redirect: 'asset-classification-index',
        children: [
          {
            path: 'asset-classification',
            name: 'asset-classification-index',
            component: ()=> import('@/views/asset'),
            meta: {
              title: '资产分类',
              icon: 'assets',
            }
          }
        ]
      },
      //定时任务
      {
        path: '',
        component: () => import('@/layout/layout-workspace'),
        redirect: 'cron-task-index',
        children: [
          {
            path: 'cron-task',
            name: 'cron-task-index',
            component: ()=> import('@/views/cron-task'),
            meta: {
              title: '定时任务',
              icon: 'time',
            }
          }
        ]
      },
      {
        path: '',
        component: () => import('@/layout/layout-workspace'),
        redirect: 'approvingIndex',
        children: [
          {
            path: 'approving',
            name: 'approvingIndex',
            component: ()=> import('@/views/approve'),
            meta: {
              title: '审批流',
              icon: 'approve',
            }
          }
        ]
      },
      {
        path: '',
        component: () => import('@/layout/layout-workspace'),
        redirect: 'projectIndex',
        children: [
          {
            path: 'project',
            name: 'projectIndex',
            component: ()=> import('@/views/project'),
            meta: {
              title: '项目管理',
              icon: 'slider-personal',
            }
          }
        ]
      },
    ]
  },
]

export default Routes;
