import Vue from 'vue'
import VueRouter from 'vue-router'
import workSpace from "./workSpace";
Vue.use(VueRouter);

const routes = [
  {
    path: '/redirect',
    component: () => import('@/layout/index'),
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/',
    redirect: 'console',
    component: () => import('@/components/layout-home-header'),
    children: [
      {
        path: 'console',
        name: 'console',
        component: () => import('@/views/cloudIndex_1'),
        meta: {
          title: '控制台'
        }
      },

    ]
  },
  {
    path: '/login',
    name: 'login',
    component: ()=> import('@/views/home/iamLogin')
  },
  {
    path: '/restPsw',
    name: 'restPsw',
    component: () => import('@/views/home/resetPsw'),
  },
  {
    path: '/restUser',
    name: 'restUser',
    component: () => import('@/views/home/resetUser'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true
  },
    ...workSpace
];

const createRouter = () => new VueRouter({
  // mode: 'history', //history模式下，浏览器地址规整，但需要后台支持
  // mode: 'hash', // hash模式下，浏览器地址不规整,带有#
  base: '/',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routes
})

const router = createRouter();

import store from '@/store'
import service from '@/api/login'
router.beforeEach((to, from, next)=> {
  const nextRoute = ['login','restPsw','404','console','restUser']
  if (nextRoute.includes(to.name)) { // 检测是否登录的页面
    next();
  } else if(store.getters.token){
    let url = to.meta.parent ? to.meta.parent : to.path
    let path = url.indexOf("/workspace") !== -1 && url.substring(10);
    service.isAuth({route: path, moduleCode: 'BASE'}).then(resp => {
      if(!resp.data){
        next({path:'/404'})
      }else {
        next()
      }
    })
  }else if(!store.getters.token){
    next({name: 'login',})
  }else {
    next();
  }
})



export default router
