import axios from "axios"
import Vue from 'vue';
import Qs from 'qs'
import router from "@/router";


const axiosService = axios.create({
  /**
   * `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
   * 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
   */
  // baseURL: 'https://some-domain.com/api/',

  /**
   * `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
   * 如果请求话费了超过 `timeout` 的时间，请求将被中断
   */
  timeout: 600000,

  // 请求头
  // 请求头信息中不能出现中文
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' // form data格式
    'Content-Type': 'application/json;charset=UTF-8' // json格式
  }
});

//请求拦截器
axiosService.interceptors.request.use(config =>{
  if(localStorage.token){
    config.headers['Authorization'] = localStorage.token;
  }

  // 用qs替代axios原配的paramsSerializer
  config.paramsSerializer = function(params) {
    return Qs.stringify(params, {arrayFormat: 'repeat'})
  };
  return config;
});

// 添响应加拦截器
axiosService.interceptors.response.use(
    function(response) {
      // Do something with response data
      const res = response.data
      if ([200, '200'].includes(res.code)) {
        // 接口状态为0，成功
        return res
      }else if ([402, '402'].includes(res.code)) {
        goLogin()
      }
      // return Promise.reject(new Error(res.message || 'Error'))
      return res
    },
    function(error) {
      // Do something with response error
      return Promise.reject(error);
    }
);


/**
 * 未登录提示
 */
import { Message,MessageBox } from 'element-ui'
function goLogin() {
  MessageBox.confirm('登录超时，请重新登录', '提示', {
    confirmButtonText: '重新登录',
    customClass: 'customMessageClass',
    showCancelButton: false,
    type: 'warning'
  }).then(() => {
    // window.location.href =`${process.env.VUE_APP_URL}/#/login`
    router.push({path: '/login'})
  })
}


let base = '';
export function postRequest(url, {params, data, responseType} = {}) {
  return axiosService({
    method: 'post',
    url: `${base}${url}`,
    params,
    data,
    responseType,
  });
}
export function putRequest(url,{params,data} = {}){
  return axiosService({
    method: 'put',
    url:`${base}${url}`,
    params,
    data,
  })
}

export function deleteRequest(url,{params} ={}){
  return axiosService({
    method:'delete',
    url:`${base}${url}`,
    params
  })
}

export function getRequest(url,{params,responseType} = {}){
  return axiosService({
    method: 'get',
    url: `${base}${url}`,
    params,
    responseType
  });
}
