<template>
  <div id="app" style="height: 100%">
    <router-view style="height: 100%"/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
