import {getRequest,postRequest,putRequest,deleteRequest} from "@/utils/request_utils"

export default {
  login: (data) => {
    return postRequest(`/api-base/login/in`,{data});
  },
  logInfo: (params)=> {
    return getRequest(`/api-base/login/info`, {params})
  },
  logOut: (params) => {
    return getRequest(`/api-base/login/out`, {params})
  },
  //修改密码
  editPsw: (data) => {
    return postRequest(`/api-base/loginUser/updatePassword`, {data})
  },
  //重置密码
  resetPSW: (data) => {
    return postRequest(`/api-base/loginUser/resetPasswordByUser`, {data})
  },
  //验证码
  sendCheckCode: (params) => {
    return getRequest(`/api-base/login/in/shotMsg`, {params})
  },
  checkPhone:(params) => {
    return getRequest(`/api-base/login/phoneExist`, {params})
  },
  resetPsw: (data) => {
    return postRequest(`/api-base/loginUser/resetPassword`, {data})
  },
  //工作态图标
  getConsoleIcon: (data) => {
    return getRequest(`/api-base/common/getMainMenu`, {data})
  },
  //基础档案路由
  getMenus: (data) => {
    return getRequest(`/api-base/common/getBaseMenu`, {data})
  },
  //判断是否有权限
  isAuth: (params) => {
    return getRequest(`/api-auth/authorization/checkPageAuth`, {params})
  },
  //获取公钥
  getLock: (data) => {
    return postRequest(`/api-base/login/publicKey`, {data})
  },
  /// iam 操作接口
  getIamToken: (params) =>{
    return getRequest('/api-base/oauth/token/code', { params: { code: params } });
  },
  // 查询用户
  getRepeatUserInfo: (params) =>{
    return getRequest('/api-base/oauth/token/user/info', { params: {code: params }})
  },







  /**
   * 强度 0 ~ 4
   * @param s
   * @returns {number}
   */
  isSimplePwd(s) {
    if (!s) { return 0; }
    if(s.length<10){ return 1; }
    let ls = 0;
    if(s.match(/([a-z])+/)){ ls++; }
    if(s.match(/([0-9])+/)){ ls++; }
    if(s.match(/([A-Z])+/)){ ls++; }
    if(s.match(/[^a-zA-Z0-9]+/)){ ls++; }
    return ls;
  },
};
