// vuex 持久化
import createPersistedState from 'vuex-persistedstate'

const UserPersisted = createPersistedState({
  key: process.env.VUE_APP_USERIFNO_KEY,
  storage: window.localStorage,
  paths: [
    'user.token',
    'user.id',
    'user.name',
    'user.phone',
    'user.domainCode',
    'user.email',
    'user.orgs',
    'user.roles',
    'user.sex',
    'user.menuList',
    'user.menuListAll',
    'user.isEditPass',
  ],
})

export default UserPersisted
