import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import "normalize.css/normalize.css" //css resets
import 'animate.css'
import '@/styles/index.scss'
import '@/assets/css/flex-box.css'
import '@/assets/css/main.css'
import '@/assets/css/main-style.scss'
import 'vue-cron-generator/src/styles/global.less'
import i18n from './i18n/i18n'

//vue-awesome-swiper
import 'swiper/swiper-bundle.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)


/**
 * 全局引入 Element UI
 * lang: zh-CN
 */
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
Vue.use(ElementUI, { locale });


import '@/icons' // svg icon图

import moment from 'moment'
Vue.prototype.$moment = moment;
moment.locale('zh-cn');

//高性能table
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable);

import JSEncrypt from 'jsencrypt';
Vue.prototype.$JSEncrypt = JSEncrypt

// 水印
import waterMark from './utils/waterMark.js';
router.afterEach((item) => {
  if (item.name !== '/login') {
    waterMark.set(store.getters.name)
  }
})



Vue.config.productionTip = false;

Vue.config.errorHandler=function (err, vm, info) {
  //info 是vue特定的错误信息
  if(err && err.response && err.response.status === 500){
    vm.$notify({type:"error",title:"服务器状态异常",message:err.response.data.message});
    return
  }else if(err && err.message === "NetworkError"){
    vm.$notify({type:"error",title:"网络状态异常",message:err.message});
    return
  }
  console.error('errorHandler', err, info);
};


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
